import { autoinject } from 'aurelia-framework';
import { Client, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class AdminIntegrationTargetAccounting {
  private clientList: Client[] =[];
  private clientId?: number;

  constructor(private notify: Notify, private api: MyHttpApi) {
  }

  async activate() {
    this.clientList = await this.api.clientList();
  }

  async transfer() {
    if (!this.clientId) {
      return;
    }
    let res = await this.api.accountingManualSend({
      id: this.clientId,
    });
    this.notify.info("client-sale.messageAccounting", res);
  }
}
