import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, PaymentTerminalSite, PaymentTerminalModel, PaymentTerminalStatus, PaymentTerminalUpdateRequest, PaymentTerminalManufacturer } from 'utils/api';

@autoinject
export class AdminPaymentTerminalsEdit {
  private new = false;
  private paymentTerminal: PaymentTerminalUpdateRequest = {
    integrationPartner: 'VERIFONE',
    serialNumber: "",
    isSpare: false,
    delete: false,
  };
  private manufacturerList: PaymentTerminalManufacturer[] = [];
  private modelList: PaymentTerminalModel[] = [];
  private siteList: PaymentTerminalSite[] = [];
  private statusList: PaymentTerminalStatus[] = [];

  // Support adding multiple terminals at once
  private newSerialNumber = "";
  private newSerialNumberList: string[] = [];
  private model?: PaymentTerminalModel;

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { id?: number }) {
    this.new = !params.id;
    [this.manufacturerList, this.modelList, this.siteList, this.statusList] = await Promise.all([
        this.api.paymentTerminalListManufacturers({ all: false }),
        this.api.paymentTerminalListModels({ all: false }),
        this.api.paymentTerminalListSites({ all: false }),
        this.api.paymentTerminalListStatuses({ all: false }),
    ]);
    if (params.id) {
      let paymentTerminal = await this.api.paymentTerminalById({ id: params.id });
      this.model = this.modelList.find(x => x.id === paymentTerminal.modelId);
      this.paymentTerminal = { ...paymentTerminal, delete: !!paymentTerminal.deleteTime, };
    }
  }

  async save(deleted: boolean) {
    if(!this.paymentTerminal.serialNumber) { // Add
        if(!this.newSerialNumberList.length) {
            return;
        }
        for(const serialNumber of this.newSerialNumberList) {
            await this.doUpdate(deleted, serialNumber);
        }
    } else { // Update
        await this.doUpdate(deleted);
    }
    this.router.navigateBack();
  }

  async doUpdate(deleted: boolean, newSerialNumber?: string) {
    const data = {
      ...this.paymentTerminal,
      delete: deleted,
    };
    if (newSerialNumber) {
      data.serialNumber = newSerialNumber;
    }
    await this.api.paymentTerminalUpdate(data);
  }

  addSerialNumber() {
    if(!this.newSerialNumber) {
        return;
    }
    this.newSerialNumberList.push(this.newSerialNumber);
    this.newSerialNumberList = Array.from(new Set(this.newSerialNumberList)); // Remove duplicates
    this.newSerialNumber = "";
  }

  removeSerialNumber(serialNumber: string) {
    this.newSerialNumberList = this.newSerialNumberList.filter(x => x != serialNumber);
  }

  @computedFrom("model.manufacturerId", "modelList")
  get modelListDynamic(): PaymentTerminalModel[] {
    const manufacturerId = this.model?.manufacturerId;
    if(!manufacturerId) {
        return [];
    }
    return this.modelList.filter(x => x.manufacturerId === manufacturerId);
  }
}
