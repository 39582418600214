import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { HelpType, HelpUpdateRequest, IdName, MyHttpApi } from 'utils/api';

@autoinject
export class AdminHelpEditHtml {
  private tuloposVersions: { id: string, name: string; }[] = [];

  private help: HelpUpdateRequest = {
    identifier: "",
    title: "",
    language: "FI",
    html: "",
    internalDocumentHtml: "",
    related: [],
    type: "POS",
  };
  private related: IdName[] = [];

  private helpList: IdName[] = [];

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id?: string, type: HelpType; }) {
    let [helpList, tuloposVersions] = await Promise.all([
      this.api.helpList({ type: params.type }),
      this.api.repositoryListBranches(),
    ]);
    this.helpList = helpList.map(x => {
      return { id: x.id, name: x.identifier + "(" + x.tuloposVersion + " " + x.language + ")" };
    });
    this.tuloposVersions = tuloposVersions.map(v => {
      return { id: v, name: v };
    });
    if (params.id) {
      let id = parseInt(params.id);
      this.helpList = this.helpList.filter(p => p.id !== id);
      let help = await this.api.helpById({ id, });
      this.help = { ...this.help, ...help, };
      let related = await this.api.helpRelatedByMainId({ id, });
      if (params.type === "POS") {
        this.related = related.map(p => this.helpList.find(x => x.id === p)!);
      }
    } else {
      if (params.type === "POS") {
        this.help.tuloposVersion = "master";
      }
      this.help.type = params.type;
    }
  }

  async save() {
    await this.api.helpUpdate({
      ...this.help,
      related: this.related.map(p => p.id),
    });
    this.router.navigateBack();
  }

  async delete() {
    if (this.help.id) {
      await this.api.helpDelete({ id: this.help.id });
    }
    this.router.navigateBack();
  }

  @computedFrom("related")
  get dynamicHelpList() {
    return this.helpList.filter(x => !(this.related.some(abg => abg.id == x.id)));
  }
}
