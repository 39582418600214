import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, RepositoryWithDataPresent } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class AdminRepositoryEdit {
  private new = false;
  private repository?: RepositoryWithDataPresent;
  private archived = false;
  
  constructor(private router: Router, private api: MyHttpApi, private notify: Notify) {
  }

  async activate(params: { id: string }) {
    this.repository = await this.api.repositoryById({ id: parseInt(params.id), asUri: true});
    this.archived = !! this.repository.archiveTime;
  }

  async save(deleted: boolean) {
    if (!this.repository) {
      return;
    }
    // If the 'archived' checkbox is unchecked and the repository was archived (archiveTime),
    // whine and don't allow unchecking the checkbox if the data has already been deleted from DB
    if (! this.archived && this.repository.archiveTime) {
      const repository = await this.api.repositoryById({ id: this.repository.id, asUri: true });
      if(! repository.dataPresent) {
        this.notify.info("repository.dataNotFound");
        this.archived = true;
      }
    }
    await this.api.repositoryUpdate({ ...this.repository, archive: this.archived, delete: deleted });
    this.router.navigateBack();
  }
}
