import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, PaymentTerminalManufacturer, PaymentTerminalUpdateModelRequest } from 'utils/api';

@autoinject
export class AdminPaymentTerminalsModelEdit {
  private new = false;
  private paymentTerminalModel: PaymentTerminalUpdateModelRequest = {
    name: "",
    manufacturerId: 0,
    allowExternalDevice: false,
    delete: false,
  };
  private paymentTerminalManufacturerList: PaymentTerminalManufacturer[] = [];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { id?: number }) {
    this.new = !params.id;
    this.paymentTerminalManufacturerList = await this.api.paymentTerminalListManufacturers({ all: false });
    if (params.id) {
      let paymentTerminalModel = await this.api.paymentTerminalModelById({ id: params.id });
      this.paymentTerminalModel = { ...paymentTerminalModel, delete: !!paymentTerminalModel.deleteTime, };
    }
  }

  async save(deleted: boolean) {
    await this.api.paymentTerminalUpdateModel({
      ...this.paymentTerminalModel,
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
