import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, Translate, TranslateLogWithModifier, TranslateUpdateRequest } from 'utils/api';
import { Notify } from 'utils/notify';
import { reloadTranslations } from 'utils/translate-util';


@autoinject
export class AdminTranslationsDefault {
  private key?: string;
  private tr: Translate = { key: "" };
  private trLogList: TranslateLogWithModifier[] = [];
  private canBelAdmin = false;

  private fieldList: FieldSpec[] = [
    { key: "modifiedBy", header: "translations.modifiedBy", type: "text", },
    { key: "modifyTime", header: "translations.modifyTime", type: "date", },
    { key: "action", header: "translations.action", type: "text", },
    { key: "message", header: "translations.message", type: "text", cssClass: "prewrapped" },
  ];

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private bindingSignaler: BindingSignaler, private notify: Notify) {
  }

  async activate(params: {key?: string}) {
    this.canBelAdmin = this.api.session?.canBelAdmin || false;
    if (params.key) {
      this.key = params.key;
      [this.tr, this.trLogList] = await Promise.all([
        this.api.publicTranslateByKey({key: params.key}),
        this.api.translateListLogByKey({key: params.key}),
      ]);
    }
  }

  async save(deleted: boolean) {
    this.tr = await this.api.translateUpdate(this.buildRequest(deleted));
    reloadTranslations(this.bindingSignaler, this.i18n, [this.tr]);
    this.router.navigateBack();
  }

  buildRequest(deleted: boolean):TranslateUpdateRequest {
    return {
      ...this.tr,
      key: this.key || this.tr.key, // XXX
      delete: deleted,
      isNew: !this.key,
    };
  }
}
