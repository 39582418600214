import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { DashboardTaskType, MyHttpApi, TaskRuntimeData } from 'utils/api';

interface UITaskRuntimeData extends TaskRuntimeData<void> {
	validationStatusText: string;
  lastFailedThrowable?: string;
	isRunning: boolean;
	rowClass: "error" | undefined;
	task?: DashboardTaskType;
}

@autoinject
export class AdminSchedulerStatus {
	private statusList: UITaskRuntimeData[] = [];
	private refreshTimeout?: NodeJS.Timeout;


	private fieldList: FieldSpec[] = [
		{ key: "name", header: "common.name", type: "text", cssClass: "not-clickable", },
		{ key: "isRunning", header: "scheduler.isRunning", type: "boolean", },
		{ key: "firstRun", header: "scheduler.firstRun", type: "date", format: 'DD.MM.YYYY HH:MM', cssClass: "not-clickable",  },
		{ key: "executions", header: "scheduler.executions", type: "number", cssClass: "not-clickable",  },
		{ key: "executionsInterrupted", header: "scheduler.executionsInterrupted", type: "number", cssClass: "not-clickable",  },
		{ key: "nextRun", header: "scheduler.nextRun", type: "date", format: 'DD.MM.YYYY HH:MM', cssClass: "not-clickable",  },
		{ key: "runningStart", header: "scheduler.runningStart", type: "date", format: 'DD.MM.YYYY HH:MM', cssClass: "not-clickable",  },
		{ key: "runningEnd", header: "scheduler.runningEnd", type: "date", format: 'DD.MM.YYYY HH:MM', cssClass: "not-clickable",  },
		{ key: "latestSuccessTime", header: "scheduler.latestSuccessTime", type: "date", format: 'DD.MM.YYYY HH:MM', cssClass: "not-clickable",  },
		{ key: "latestSuccessResult", header: "scheduler.latestSuccessResult", type: "text", cssClass: "not-clickable",  },
		{ key: "latestFailedTime", header: "scheduler.latestFailedTime", type: "date", format: 'DD.MM.YYYY HH:MM', cssClass: "not-clickable",  },
		{ key: "latestFailedThrowable", header: "scheduler.latestFailedThrowable", type: "text", cssClass: "not-clickable",  },
		{ key: "validationStatusText", header: "scheduler.validationStatus", type: "text", cssClass: "prewrapped not-clickable",  },
	];

	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }
  
	async activate() {
    await this.refresh();
		this.refreshTimeout = setInterval(() => this.refresh(), 60000);
	}

	async refresh() {
		const data = await this.api.schedulerStatus();
		const res: UITaskRuntimeData[] = [];
		for (const x of data) {
			const item: UITaskRuntimeData = {
				...x.runtimeData,
        lastFailedThrowable: x.lastFailedThrowable,
				validationStatusText: x.runtimeData.validationStatus.join("\n"),
				isRunning: x.isRunning,
				rowClass: x.isRunning ? undefined : "error",
				task: x.task,
			};
			res.push(item);
		}
		this.statusList = res;
	}

	async rowCall(key: string, row: UITaskRuntimeData) {
		if (key === "isRunning" && row.task) {
			await this.api.schedulerToggleTask({ task: row.task });
			await this.refresh();
		}
	}

	deactivate(): void {
		if (this.refreshTimeout) {
			clearInterval(this.refreshTimeout);
			this.refreshTimeout = undefined;
		}
	}
}
