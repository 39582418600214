import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { IntegrationErrorMessageMapper, MyHttpApi } from 'utils/api';


@autoinject
export class AdminIntegrationErrorMessageMapperList {
  private defaults: { [key: string]: string } = {"deleted" : " "};
  private integrationErrorMessageMapperList: IntegrationErrorMessageMapper[] = [];

  private fieldList: FieldSpec[] = [
    { key: "id", header: "#", type: "number", },
    { key: "regex", header: "integrationErrorMessageMapper.regex", type: "text"},
    { key: "textFi", header: "integrationErrorMessageMapper.textFi", type: "text" },
    { key: "textEn", header: "integrationErrorMessageMapper.textEn", type: "text", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    let integrationErrorMessageMapperList = await this.api.integrationErrorMessageMapperListRegex({ all: true });
    this.integrationErrorMessageMapperList = integrationErrorMessageMapperList.map(x => {
      return {
        ...x, deleted: !!x.deleteTime
      }
    });
  }

  rowCall(key: string, row: IntegrationErrorMessageMapper) {
    if (row.id) {
      this.router.navigateToRoute("admin/integration-error-message-mapper/edit", { id: ""+row.id });
    }
  }
}
