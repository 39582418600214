import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, NewsDTO, NewsType } from 'utils/api';

interface UINewsDTO extends NewsDTO {
  rowClass?: string;
}

@autoinject
export class AdminNewsList {
  private newsList: UINewsDTO[] = [];
  private typeString?: string;
  private type?: NewsType;
  private editUrl = "admin/news/edit";

  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
  ];

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: {type: NewsType}) {
    let lang = this.i18n.getLocale() == 'en' ? 'En' : 'Fi';
    this.fieldList.push({ key: "topic" + lang, header: this.i18n.tr("news.topic", {lang: lang.toUpperCase()} as {}), type: "text"});
    this.fieldList.push({ key: "createTime", header: "news.createTime", type: "date" });
    this.fieldList.push({ key: "createActorName", header: "news.createdBy", type: "text" });
    this.fieldList.push({ key: "modifyTime", header: "news.modifyTime", type: "date" });
    this.fieldList.push({ key: "modifyActorName", header: "news.modifiedBy", type: "text" });
    if (this.api.session?.canSuperuser) {
      this.fieldList.push({ key: "published", header: "news.published", type: "boolean", });
    }
    await this.refresh(params);
  }

  async refresh(params: {type: NewsType}) {
    this.typeString = this.i18n.tr("news." + params.type).toLowerCase();
    this.type = params.type;
    if (this.type === "ALERT") {
      this.editUrl = "admin/news/urgent/edit";
    }

    let [lastAlert, newsList] = await Promise.all([
      this.api.newsLatestAlert(),
      this.api.newsAdminList({newsType: this.type} ),
    ]);
    this.newsList = newsList.map(x => ( {...x,  rowClass: (lastAlert && lastAlert.id === x.id) ? "news-active" : "" }));
  }
  rowCall(key: string, row: NewsDTO) {
    if (row.id) {
      this.router.navigateToRoute(this.editUrl, {id:  row.id, type: row.newsType });
    }
  }
}
