import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router, activationStrategy } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, ReleaseLog, ReleaseLogListInternalRequest } from 'utils/api';

@autoinject
export class AdminReleaseLogList {
	private releaseLogList: ReleaseLog[] = [];
	private searchCriteria: ReleaseLogListInternalRequest = { type: "DASHBOARD" };

	private fieldList: FieldSpec[] = [
		{ key: "id", header: "ID", type: "text", },
		{ key: "createTime", header: "releaseLog.updateTime", type: "date" },
		{ key: "branch", header: "releaseLog.branch", type: "text", },
		{ key: "abbreviatedSha", header: "SHA", type: "text", },
		{ key: "technicalDescription", header: "releaseLog.technicalDesc", type: "text", },
	];

	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
	}

	determineActivationStrategy() {
		return activationStrategy.replace;
	}

	async created() {
		if (this.api.session?.canSuperuser) {
			this.fieldList.push({ key: "published", header: "releaseLog.published", type: "boolean", });
		}
		if (this.router.currentInstruction.config.settings?.type) {
			this.searchCriteria.type = this.router.currentInstruction.config.settings?.type;
		}
		await this.refresh();
	}

	async refresh() {
		this.releaseLogList = await this.api.releaseLogList(this.searchCriteria);
	}
	
	rowCall(key: string, row: ReleaseLog) {
		if (row.id) {
			this.router.navigateToRoute("admin/release-log/edit", { id: "" + row.id });
		}
	}
}
