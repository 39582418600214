import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PaymentTerminalStatus } from 'utils/api';


interface UIPaymentTerminalStatus extends PaymentTerminalStatus {
  deleted: boolean;
}

@autoinject
export class AdminPaymentTerminalsStatusList {
  private defaults: { [key: string]: string; } = { "deleted": " " };
  private statusList: UIPaymentTerminalStatus[] = [];

  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "deleteTime", header: "common.deleteTime", type: "date", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    const statusList = await this.api.paymentTerminalListStatuses({ all: true });
    this.statusList = statusList.map(x => ( { ...x, deleted: !!x.deleteTime }));
  }

  rowCall(key: string, row: UIPaymentTerminalStatus) {
    if (row.id) {
      this.router.navigateToRoute("admin/payment-terminals/status-edit", { id: "" + row.id });
    }
  }
}
