import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, PaymentTerminalManufacturer, PaymentTerminalUpdateManufacturerRequest } from 'utils/api';

@autoinject
export class AdminPaymentTerminalsManufacturerEdit {
	private new = false;
	private paymentTerminalManufacturer: PaymentTerminalUpdateManufacturerRequest = {
		name: "",
		delete: false,
	};
	private paymentTerminalManufacturerList: PaymentTerminalManufacturer[] = [];

	constructor(private api: MyHttpApi, private router: Router) {
	}

	async activate(params: { id?: number }) {
		this.new = !params.id;
		this.paymentTerminalManufacturerList = await this.api.paymentTerminalListManufacturers({ all: false });
		if (params.id) {
			let paymentTerminalManufacturer = await this.api.paymentTerminalManufacturerById({ id: params.id });
			this.paymentTerminalManufacturer = { ...paymentTerminalManufacturer, delete: !!paymentTerminalManufacturer.deleteTime, };
		}
	}

	async save(deleted: boolean) {
		await this.api.paymentTerminalUpdateManufacturer({
			...this.paymentTerminalManufacturer,
			delete: deleted,
		});
		this.router.navigateBack();
	}
}
