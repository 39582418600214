import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, Repository, RepositoryListInternalRequest } from 'utils/api';

interface UIRepository extends Repository {
  archived: boolean;
}

@autoinject
export class AdminRepositoryList {
  private repositoryList: UIRepository[] = [];
  private repoSearchCriteria: RepositoryListInternalRequest = {onlyStable: false};

  private defaults: { [key: string]: string } = {"archived" : " "};

  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text" },
    { key: "createTime", header: "repository.createTime", type: "date" },
    { key: "branch", header: "repository.branch", type: "text", },
    { key: "abbreviatedSha", header: "SHA", type: "text", },
    { key: "name", header: "repository.name", type: "text", },
    { key: "archived", header: "repository.archived", type: "boolean", },
  ];
  
  constructor(private api: MyHttpApi, private router: Router) {
  }
  
  async activate() {
    if (this.api.session?.canSuperuser) {
      this.fieldList.push({ key: "releaseType", header: "repository.releaseType", type: "enum", enum: "ReleaseType", });
    }
    await this.refresh();
  }

  async refresh() {
    if (!this.api.session?.canSuperuser) {
      this.repoSearchCriteria.onlyStable = true;
    }
    const repositoryList = await this.api.repositoryList(this.repoSearchCriteria);
    this.repositoryList = repositoryList.map(x => ({ ...x, archived: !! x.archiveTime }));
  }

  async archive(row: Repository) {
    await this.api.repositoryArchive({ id: row.id});
    await this.refresh();
  }
  rowCall(key: string, row: Repository) {
    if (row.id) {
      this.router.navigateToRoute("admin/repository/edit", {id: ""+ row.id});
    }
  }

}
