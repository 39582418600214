import { autoinject, computedFrom } from 'aurelia-framework';
import { MyHttpApi, PrivilegeItem, ProductCategory, StorageUnit } from 'utils/api';
import { getSelectedCorporation } from 'utils/corporation-util';
import { MasterItem, masterItem } from 'utils/master-item-util';

export interface UIProductCategory extends MasterItem<ProductCategory> {
  storageUnitName?: string;
}

@autoinject
export class AdminMasterItemProductCategory {
  private privilege?: PrivilegeItem;
  private list: UIProductCategory[] = [];
  private storageUnitList: StorageUnit[] = [];

  constructor(private api: MyHttpApi) { }

  async activate() {
    this.privilege = getSelectedCorporation();
    if (this.privilege) {
      this.storageUnitList = await this.api.storageUnitListMaster();
    }
    await this.reload();
  }

  async reload() {
    if (!this.privilege) {
      this.list = [];
      return;
    }
    const [list, childList] = await Promise.all([
      this.api.productCategoryListMaster(),
      this.api.productCategoryList({ ...this.privilege }),
    ]);
    this.list = list.filter(x => !x.deleteTime)
      .map(x => ({
          ...masterItem(x, childList, this.privilege),
          storageUnitName: this.storageUnitList.find(su => su.id === x.defaultStorageUnitId)?.name
        }));
  }

  @computedFrom("privilege.id", "privilege.type")
  get privilegeObserver() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reload();
    return "";
  }

  async copy(item: UIProductCategory) {
    if (item.isMoved) {
      return;
    }
    await this.doCopy([item]);
  }

  async copyAll() {
    await this.doCopy(this.list.filter(x => !x.isMoved));
  }

  async doCopy(list: UIProductCategory[]) {
    if (!this.privilege) {
      return;
    }
    await this.api.productCategoryCopyMasterItems({
      ...this.privilege, list,
    });
    await this.reload();
  }

  @computedFrom("list")
  get moveAllDisabled() {
    return this.list.filter(x => !x.isMoved).length === 0;
  }
}
