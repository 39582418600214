import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, VivaWalletIsvCredentialsUpdateRequest } from 'utils/api';

@autoinject
export class VivaWalletIsvCredentialsEdit {
	private credentials: VivaWalletIsvCredentialsUpdateRequest = {
		clientId: "",
		clientSecret: "",
	};

	constructor(private api: MyHttpApi, private router: Router) {
	}

	async activate() {
		let credentialsMaybe = await this.api.vivaWalletIsvCredentialsGet();
		if (!credentialsMaybe) {
			return;
		}
		this.credentials = credentialsMaybe;
	}

	async save() {
		await this.api.vivaWalletIsvCredentialsUpdate(this.credentials);
		this.router.navigateBack();
	}
}
