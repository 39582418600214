import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { IntegrationErrorMessageMapper, MyHttpApi } from 'utils/api';

@autoinject
export class AdminIntegrationErrorMessageMapperEdit {
  private mapperData?: IntegrationErrorMessageMapper;
  private id: number = 0;

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id?: string; }) {
    if(!params.id) {
      return;
    }
    this.id = parseInt(params.id);
    this.mapperData = await this.api.integrationErrorMessageMapperRegexById({ id: this.id });
  }

  async save(deleted: boolean) {
    if(!this.mapperData) {
      return;
    }
    await this.api.integrationErrorMessageMapperRegexUpdate({ ...this.mapperData, delete: deleted });
    this.router.navigateToRoute("admin/integration-error-message-mapper/list");
  }
}
