import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Help, HelpType, MyHttpApi } from 'utils/api';

@autoinject
export class AdminHelpListHtml {
  private list: Help[] = [];
  private type?: HelpType;
  private editUrl = "admin/help/edit-html";

  private fields: FieldSpec[] = [
    { header: "help.identifier", key: "identifier", type: "text",},
    { header: "help.language", key: "language", type: "text", },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: {type: HelpType}) {
    this.type = params.type;
    if (this.type === "DASHBOARD") {
      this.editUrl = "admin/help/dashboard-edit-html"
    } else if (this.type === "NETSTORE") {
      this.editUrl = "admin/help/webstore-edit-html"
    } else {
      this.fields.push({ header: "help.tuloposVersion", key: "tuloposVersion", type: "text", },);
    }
    this.list = await this.api.helpList({type: this.type});
  }

  rowCall(key: string, row: Help) {
    if (row.id) {
      this.router.navigateToRoute(this.editUrl, {id:  row.id, type: row.type});
    }
  }
}
