import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PaymentTerminalSite } from 'utils/api';

interface UIPaymentTerminalSite extends PaymentTerminalSite {
  deleted: boolean;
}

@autoinject
export class AdminPaymentTerminalsSiteList {
  private defaults: { [key: string]: string; } = { "deleted": " " };
  // @observable({ changeHandler: "addItem" })
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private siteList: UIPaymentTerminalSite[] = [];
  private newItemName = "";

  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "deleteTime", header: "common.deleteTime", type: "date", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    const siteList = await this.api.paymentTerminalListSites({ all: true });
    this.siteList = siteList.map(x => ( { ...x, deleted: !!x.deleteTime }));
  }

  rowCall(key: string, row: PaymentTerminalSite) {
    if (row.id) {
      this.router.navigateToRoute("admin/payment-terminals/site-edit", { id: "" + row.id });
    }
  }
}
