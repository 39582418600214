import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, PaymentTerminalUpdateSiteOrStatusRequest } from 'utils/api';

@autoinject
export class AdminPaymentTerminalsSiteEdit {
  private new = false;
  private paymentTerminalSite: PaymentTerminalUpdateSiteOrStatusRequest = {
    name: "",
    delete: false,
  };

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { id?: number }) {
    this.new = !params.id;
    if (params.id) {
      let paymentTerminalSite = await this.api.paymentTerminalSiteById({ id: params.id });
      this.paymentTerminalSite = { ...paymentTerminalSite, delete: !!paymentTerminalSite.deleteTime, };
    }
  }

  async save(deleted: boolean) {
    await this.api.paymentTerminalUpdateSite({
      ...this.paymentTerminalSite,
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
