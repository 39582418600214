import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { Language, MyHttpApi, Translate, TranslateConfigMismatchResponse } from 'utils/api';
import { Notify } from 'utils/notify';
import { reloadTranslations } from 'utils/translate-util';


@autoinject
export class AdminTranslationsDefault {
  private jsonFiles?: FileList;
  private language: Language = "FI";
  private configMismatch?: TranslateConfigMismatchResponse;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private bindingSignaler: BindingSignaler, private notify: Notify) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    this.configMismatch = await this.api.translateConfigMismatch();
  }

  async uploadJsonFile() {
    if (!this.jsonFiles) {
      return;
    }

    let reader = new FileReader();
    reader.addEventListener("load", async e => {
      if (typeof reader.result === "string") {
        let file = reader.result.replace(/^[^,]+,/, "");
        try {
          let translations = await this.api.translateJsonUpdate({
            language: this.language,
            json: file,
          });
          reloadTranslations(this.bindingSignaler, this.i18n, translations);
          this.notify.info("common.updateDone");
          this.emptyFileInput();
          await this.refresh();
        } catch (error) {
          this.emptyFileInput();
          throw error;
        }
      }
    });
    reader.readAsDataURL(this.jsonFiles[0]);
  }

  @computedFrom("language")
  get jsonDownloadUrl() {
    return this.api.translateJsonDownloadUrl({ language: this.language });
  }

  get excelDownloadUrl() {
    return this.api.translateExcelDownloadUrl();
  }

  emptyFileInput() {
    document.querySelectorAll("#jsonFiles").forEach(x => (x as HTMLInputElement).value = "");
  }

  @computedFrom("configMismatch")
  get hasConfigMismatch() {
    return this.configMismatch?.deleted.length || this.configMismatch?.mismatch.length || this.configMismatch?.emptyStrings.length;
  }

  async deleteList(list: Translate[]) {
    if (!list.length) {
      return;
    }
    if (!confirm(this.i18n.tr("common.confirmDelete"))) {
      return;
    }
    await this.api.translateDeleteList({ delete: list });
    await this.refresh();
  }
}
