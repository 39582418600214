import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PaymentTerminalManufacturer, PaymentTerminalModel } from 'utils/api';

interface UIPaymentTerminalModel extends PaymentTerminalModel {
  deleted: boolean;
}

@autoinject
export class AdminPaymentTerminalsModelList {
  private defaults: { [key: string]: string; } = { "deleted": " " };
  private modelList: UIPaymentTerminalModel[] = [];
  private manufacturers: { [key: number]: PaymentTerminalManufacturer; } = {};

  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "manufacturerId", header: "payment-terminal.manufacturer", type: "lookup", lookupData: this.manufacturers, lookupKey: "name" },
    { key: "deleteTime", header: "common.deleteTime", type: "date", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    const [manufacturerList, modelList] = await Promise.all([
      this.api.paymentTerminalListManufacturers({ all: true }),
      this.api.paymentTerminalListModels({ all: true }),
    ]);
    this.modelList = modelList.map(x => ( { ...x, deleted: !!x.deleteTime, }));

    for (let x of manufacturerList) {
      this.manufacturers[x.id] = x;
    }
  }

  rowCall(key: string, row: PaymentTerminalModel) {
    if (row.id) {
      this.router.navigateToRoute("admin/payment-terminals/model-edit", { id: "" + row.id });
    }
  }
}
