import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, PaymentTerminalUpdateSiteOrStatusRequest } from 'utils/api';

@autoinject
export class AdminPaymentTerminalsStatusEdit {
  private new = false;
  private paymentTerminalStatus: PaymentTerminalUpdateSiteOrStatusRequest = {
    name: "",
    delete: false,
  };

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { id?: number }) {
    this.new = !params.id;
    if (params.id) {
      let paymentTerminalStatus = await this.api.paymentTerminalStatusById({ id: params.id });
      this.paymentTerminalStatus = { ...paymentTerminalStatus, delete: !!paymentTerminalStatus.deleteTime, };
    }
  }

  async save(deleted: boolean) {
    await this.api.paymentTerminalUpdateStatus({
      ...this.paymentTerminalStatus,
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
