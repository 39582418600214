import { autoinject, observable } from 'aurelia-framework';
import { Client, ClientAccounting, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class AdminIntegrationSourceAccounting {
  private clientList: Client[] = [];

  @observable({ changeHandler: "clientChanged" })
  private clientId?: number;
  private minAccountingId = 0;
  private businessDate?: Date;
  private log?: ClientAccounting;

  constructor(private notify: Notify, private api: MyHttpApi) {
  }

  async activate() {
    this.clientList = await this.api.clientList();
  }
  
  async clientChanged() {
    this.log = undefined;
    if (this.clientId) {
      this.log = await this.api.accountingLatest({ clientId: this.clientId });
    }
  }

  async transfer() {
    if (!this.clientId || this.minAccountingId == undefined) {
      return;
    }
    let result = await this.api.accountingFetch({
      clientId: this.clientId,
      minAccountingId: this.minAccountingId,
      businessDate: this.businessDate,
    });
    this.notify.info("client-sale.transferAccounting", result);
    await this.clientChanged();
  }

  async deleteLog() {
    if (!this.log) {
      return;
    }
    await this.api.accountingDelete({ id: this.log.id });
    await this.clientChanged();
  }
}
