// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form,
div#config-mismatch {
  padding-top: 2em;
  padding-bottom: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/admin/translations/default.less"],"names":[],"mappings":"AAAA;;EACI,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":["form, div#config-mismatch {\n    padding-top: 2em;\n    padding-bottom: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
