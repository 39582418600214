import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, ReleaseLog } from 'utils/api';

@autoinject
export class AdminReleaseLogEdit {
  private new = false;
  private releaseLog?: ReleaseLog;

  constructor(private router: Router, private api: MyHttpApi) {
  }

  async activate(params: { id: string; }) {
    this.releaseLog = await this.api.releaseLogById({ id: parseInt(params.id), asUri: true });
  }

  created() {
	this.router.currentInstruction.config.settings.titleKey = this.releaseLog?.type === "NETSTORE" ? "nettikauppa" : "dashboard";
  }

  async save(deleted: boolean) {
    if (!this.releaseLog) {
      return;
    }
    await this.api.releaseLogUpdate({ ...this.releaseLog, delete: deleted });
    this.router.navigateBack();
  }
}
