import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PaymentTerminalManufacturer } from 'utils/api';

interface UIPaymentTerminalManufacturer extends PaymentTerminalManufacturer {
	deleted: boolean;
}

@autoinject
export class AdminPaymentTerminalsManufacturerList {
	private defaults: { [key: string]: string; } = { "deleted": " " };
	private manufacturerList: UIPaymentTerminalManufacturer[] = [];

	private fieldList: FieldSpec[] = [
		{ key: "name", header: "common.name", type: "text", },
		{ key: "deleteTime", header: "common.deleteTime", type: "date", },
		{ key: "deleted", header: "common.deleted", type: "boolean", },
	];

	constructor(private api: MyHttpApi, private router: Router) {
	}

	async activate() {
		const manufacturerList = await this.api.paymentTerminalListManufacturers({ all: true });
		this.manufacturerList = manufacturerList.map(x => ({ ...x, deleted: !!x.deleteTime, }));
	}

	rowCall(key: string, row: PaymentTerminalManufacturer) {
		if (row.id) {
			this.router.navigateToRoute("admin/payment-terminals/manufacturer-edit", { id: "" + row.id });
		}
	}
}
