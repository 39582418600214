import { autoinject, computedFrom } from 'aurelia-framework';
import { MyHttpApi, PrivilegeItem, StorageUnit } from 'utils/api';
import { getSelectedCorporation } from 'utils/corporation-util';
import { MasterItem, masterItem } from 'utils/master-item-util';

@autoinject
export class AdminMasterItemStorageUnit {
  private privilege?: PrivilegeItem;
  private list: MasterItem<StorageUnit>[] = [];

  constructor(private api: MyHttpApi) { }

  async activate() {
    this.privilege = getSelectedCorporation();
    await this.reload();
  }

  async reload() {
    if (!this.privilege) {
      this.list = [];
      return;
    }
    const [list, childList] = await Promise.all([
      this.api.storageUnitListMaster(),
      this.api.storageUnitList({ ...this.privilege }),
    ]);
    this.list = list.filter(x => !x.deleteTime).map(x => (masterItem(x, childList, this.privilege)));
  }

  @computedFrom("privilege.id", "privilege.type")
  get privilegeObserver() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reload();
    return "";
  }

  async copy(item: MasterItem<StorageUnit>) {
    if (item.isMoved) {
      return;
    }
    await this.doCopy([item]);
  }

  async copyAll() {
    await this.doCopy(this.list.filter(x => !x.isMoved));
  }

  async doCopy(list: MasterItem<StorageUnit>[]) {
    if (!this.privilege) {
      return;
    }
    await this.api.storageUnitCopyMasterItems({
      ...this.privilege, list,
    });
    await this.reload();
  }

  @computedFrom("list")
  get moveAllDisabled() {
    return this.list.filter(x => !x.isMoved).length === 0;
  }
}
