import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, MyHttpApi, ParamsType, ServerListResponse, ServerLocation, ServerType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

@autoinject
export class AdminServersList extends BaseRoute {
  private serverList: ServerListResponse[] = [];
  private clients: { [key: number]: Client; } = {};

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.serverList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: "search" })
  private id?: string;
  @observable({ changeHandler: "search" })
  private serialNumber?: string;
  @observable({ changeHandler: "search" })
  private serverLocation?: ServerLocation;
  @observable({ changeHandler: "search" })
  private clientName?: string;
  @observable({ changeHandler: "search" })
  private serverType?: ServerType;
  @observable({ changeHandler: "search" })
  private showRetired = false;
  @observable({ changeHandler: "search" })
  private startAcquiredDate?: Date;
  @observable({ changeHandler: "search" })
  private endAcquiredDate?: Date;

  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "serialNumber", header: "servers.serial", type: "text", },
    { key: "acquired", header: "servers.acquired", type: "date", format: "DD.MM.YYYY" },
    { key: "serverLocation", header: "servers.location", type: "enum", enum: "ServerLocations" },
    { key: "clientName", header: "servers.client", type: "text", },
    { key: "serverType", header: "servers.type", type: "enum", enum: "ServerTypes" },
    { key: "retired", header: "servers.retired", type: "date", format: "DD.MM.YYYY" },
    { key: "lastSeen", header: "servers.lastSeen", type: "date", },
  ];

  protected override routeParams() {
    return {
      "id": String,
      "serialNumber": String,
      "serverLocation": String,
      "clientName": String,
      "serverType": String,
      "showRetired": Boolean,
      "startAcquiredDate": Date,
      "endAcquiredDate": Date,
    };
  }

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
    super();
  }

  async activate(params: ParamsType) {
    super.activate(params);
    await this.search();
  }

  // For base-route
  buildQueryParams() {
    return {
      id: this.id,
      serialNumber: this.serialNumber,
      serverLocation: <ServerLocation>this.serverLocation,
      clientName: this.clientName,
      serverType: <ServerType>this.serverType,
      showRetired: this.showRetired,
      startAcquiredDate: this.startAcquiredDate,
      endAcquiredDate: this.endAcquiredDate,
    };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: ServerListResponse[]) {
    this.serverList = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  @computedFrom("id", "serialNumber", "serverLocation", "clientName", "serverType", "showRetired", "startAcquiredDate", "endAcquiredDate", "i18n.i18next.language")
  get excelLink() {
    return this.api.serverListExcelUrl({ ...this.buildQueryParams(), lang: this.i18n.getLocale() });
  }

  rowCall(key: string, row: ServerListResponse) {
    if (row.id) {
      this.router.navigateToRoute("admin/servers/edit", { id: "" + row.id });
    }
  }
}
