import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { HealthTerminal, MyHttpApi, PaymentTerminalManufacturer, PaymentTerminalModel, PaymentTerminalSite, PaymentTerminalStatus, PaymentTerminalWithClientData } from 'utils/api';

interface UIHealthTerminal extends HealthTerminal {
  clientName: string;
  deleted: boolean;
}

@autoinject
export class AdminPaymentTerminalsList {
  private defaults: { [key: string]: string; } = { "deleted": " " };

  private manufacturers: { [key: number]: PaymentTerminalManufacturer; } = {};
  private models: { [key: number]: PaymentTerminalModel; } = {};
  private sites: { [key: number]: PaymentTerminalSite; } = {};
  private statuses: { [key: number]: PaymentTerminalStatus; } = {};

  private terminalList: PaymentTerminalWithClientData[] = [];
  private reportTerminalList: UIHealthTerminal[] = [];

  private reportFieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "name", header: "common.name", type: "text", },
    { key: "serialNumber", header: "payment-terminal.serialNumber", type: "text", },
    { key: "clientName", header: "payment-terminal.clientName", type: "text", },
    { key: "locationName", header: "payment-terminal.locationName", type: "text", },
    { key: "areaName", header: "payment-terminal.areaName", type: "text", },
    { key: "stationName", header: "payment-terminal.stationName", type: "text", },
    { key: "lastTerminalQueueTime", header: "payment-terminal.lastTerminalQueueTime", type: "date", },
    { key: "lastTerminalVersion", header: "payment-terminal.lastTerminalVersion", type: "text", },
    { key: "deleteTime", header: "common.deleteTime", type: "date", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "serialNumber", header: "payment-terminal.serialNumber", type: "text", },
    { key: "integrationPartner", header: "payment-terminal.integrationPartner", type: "enum", enum: "PaymentTerminalIntegrationPartner" },
    { key: "modelId", header: "payment-terminal.model", type: "lookup", lookupData: this.models, lookupKey: "name" },
    { key: "isSpare", header: "payment-terminal.isSpare", type: "boolean", },
    { key: "siteId", header: "payment-terminal.site", type: "lookup", lookupData: this.sites, lookupKey: "name" },
    { key: "statusId", header: "payment-terminal.status", type: "lookup", lookupData: this.statuses, lookupKey: "name" },
    { key: "owner", header: "payment-terminal.owner", type: "enum", enum: "PaymentTerminalOwner" },
    { key: "clientName", header: "unit.unit", type: "text", },
    { key: "integrationState", header: "payment-terminal.integrationState", type: "enum", enum: "PaymentTerminalIntegrationState" },
    { key: "deleteTime", header: "common.deleteTime", type: "date", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    const [reportTerminals, paymentTerminals, manufacturerList, modelList, siteList, statusList, ] = await Promise.all([
      this.api.reportTerminals(),
      this.api.paymentTerminalListWithClientData({ all: true }),
      this.api.paymentTerminalListManufacturers({ all: true }),
      this.api.paymentTerminalListModels({ all: true }),
      this.api.paymentTerminalListSites({ all: true }),
      this.api.paymentTerminalListStatuses({ all: true }),
    ]);
    reportTerminals.forEach(x => {
      x.terminals.forEach(t => {
        const deleteTime = t.deleteTime || x.terminalModule?.deleteTime;
        let item = {
          clientName: x.clientName,
          ...t,
          deleteTime,
          deleted: !!deleteTime,
        };
        this.reportTerminalList.push(item);
      });
    });
    this.terminalList = paymentTerminals.map(x => ({ ...x, deleted: !!x.deleteTime }));

    for (let x of manufacturerList) {
      this.manufacturers[x.id] = x;
    }

    for (let x of modelList) {
      this.models[x.id] = x;
    }

    for (let x of siteList) {
      this.sites[x.id] = x;
    }

    for (let x of statusList) {
      this.statuses[x.id] = x;
    }
  }

  rowCall(key: string, row: PaymentTerminalWithClientData) {
    if (row.id) {
      this.router.navigateToRoute("admin/payment-terminals/edit", { id: "" + row.id });
    }
  }
}
