import { autoinject, computedFrom } from 'aurelia-framework';
import { MyHttpApi, PrivilegeItem, ProductCategory, ProductSubCategory, StorageUnit } from 'utils/api';
import { getSelectedCorporation } from 'utils/corporation-util';
import { MasterItem, masterItem } from 'utils/master-item-util';

export interface UIProductSubCategory extends MasterItem<ProductSubCategory> {
  productCategoryName?: string;
  storageUnitName?: string;
}

@autoinject
export class AdminMasterItemProductSubCategory {
  private privilege?: PrivilegeItem;
  private list: UIProductSubCategory[] = [];
  private productCategoryMap: {[key: string]: ProductCategory} = {};
  private storageUnitMap: {[key: string]: StorageUnit} = {};

  constructor(private api: MyHttpApi) { }

  async activate() {
    this.privilege = getSelectedCorporation();
    if (this.privilege) {
      const [productCategoryList, storageUnitList] = await Promise.all([
        this.api.productCategoryListMaster(),
        this.api.storageUnitListMaster(),
      ]);
        this.storageUnitMap = MyHttpApi.toHashStr(storageUnitList);
        this.productCategoryMap = MyHttpApi.toHashStr(productCategoryList);
    }
    await this.reload();
  }

  async reload() {
    if (!this.privilege) {
      this.list = [];
      return;
    }
    const [list, childList] = await Promise.all([
      this.api.productSubCategoryListMaster(),
      this.api.productSubCategoryList({ ...this.privilege }),
    ]);
    this.list = list.filter(x => !x.deleteTime)
      .map(x => ({
        ...masterItem(x, childList, this.privilege),
        productCategoryName: this.productCategoryMap[x.productCategoryId]?.name,
        storageUnitName: this.storageUnitMap[x.defaultStorageUnitId || ""]?.name
      }));
  }

  @computedFrom("privilege.id", "privilege.type")
  get privilegeObserver() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reload();
    return "";
  }

  async copy(item: UIProductSubCategory) {
    if (item.isMoved) {
      return;
    }
    await this.doCopy([item]);
  }

  async copyAll() {
    await this.doCopy(this.list.filter(x => !x.isMoved));
  }

  async doCopy(list: UIProductSubCategory[]) {
    if (!this.privilege) {
      return;
    }
    await this.api.productSubCategoryCopyMasterItems({
      ...this.privilege, list,
    });
    await this.reload();
  }

  @computedFrom("list")
  get moveAllDisabled() {
    return this.list.filter(x => !x.isMoved).length === 0;
  }
}
