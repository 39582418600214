import { autoinject, computedFrom } from 'aurelia-framework';
import { MyHttpApi, PricingGroup, PrivilegeItem, ProductCategory, ProductSubCategory } from 'utils/api';
import { getSelectedCorporation } from 'utils/corporation-util';
import { MasterItem, masterItem } from 'utils/master-item-util';

export interface UIPricingGroup extends MasterItem<PricingGroup> {
  productCategoryName?: string;
  productSubCategoryName?: string;
}

@autoinject
export class AdminMasterItemPricingGroup {
  private privilege?: PrivilegeItem;
  private list: UIPricingGroup[] = [];
  private productCategoryList: ProductCategory[] = [];
  private productSubCategoryList: ProductSubCategory[] = [];

  constructor(private api: MyHttpApi) { }

  async activate() {
    this.privilege = getSelectedCorporation();
    if (this.privilege) {
      [this.productCategoryList, this.productSubCategoryList] = await Promise.all([
        this.api.productCategoryListMaster(),
        this.api.productSubCategoryListMaster(),
      ]);
    }
    await this.reload();
  }

  async reload() {
    if (!this.privilege) {
      this.list = [];
      return;
    }
    const [list, childList] = await Promise.all([
      this.api.pricingGroupListMaster(),
      this.api.pricingGroupList({ ...this.privilege }),
    ]);
    this.list = list.filter(x => !x.deleteTime)
      .map(x => ({
          ...masterItem(x, childList, this.privilege),
          productCategoryName: this.productCategoryList.find(pc => pc.id === x.productCategoryId)?.name,
          productSubCategoryName: this.productSubCategoryList.find(pc => pc.id === x.productSubCategoryId)?.name,
        }));
  }

  @computedFrom("privilege.id", "privilege.type")
  get privilegeObserver() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reload();
    return "";
  }

  async copy(item: UIPricingGroup) {
    if (item.isMoved) {
      return;
    }
    await this.doCopy([item]);
  }

  async copyAll() {
    await this.doCopy(this.list.filter(x => !x.isMoved));
  }

  async doCopy(list: UIPricingGroup[]) {
    if (!this.privilege) {
      return;
    }
    await this.api.pricingGroupCopyMasterItems({
      ...this.privilege, list,
    });
    await this.reload();
  }

  @computedFrom("list")
  get moveAllDisabled() {
    return this.list.filter(x => !x.isMoved).length === 0;
  }
}
